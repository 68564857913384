import { Component, inject } from '@angular/core';

import { Title } from '@angular/platform-browser';
import { RouterOutlet } from '@angular/router';
import {
  DsaAppShellModule,
  DsaAppShellService,
  DsaMenuService
} from '@dsa/design-system-angular';
import { environment } from '@env/environment';
import { select, Store } from '@ngrx/store';
import { OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { Observable } from 'rxjs';
import packageInfo from '../../package.json';
import {
  APPLICATION_MENU_CORE,
  APPLICATION_MENU_DYNAMIC_CONNECT,
  APPLICATION_MENU_DYNAMIC_CONNECT_EDIT
} from './app.commons';
import { CoreActions } from './modules/core/actions/core.actions';
import { InstanaComponent } from './modules/core/components/instana/instana.component';
import * as fromApp from './reducers';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [DsaAppShellModule, RouterOutlet, InstanaComponent]
})
export class AppComponent {
  private readonly store = inject<Store<fromApp.State>>(Store);
  private readonly oauthService = inject(OAuthService);
  private readonly dsaAppShellService = inject(DsaAppShellService);
  private readonly dsaMenuService = inject(DsaMenuService);
  private readonly titleService = inject(Title);
  private readonly window = inject(Window);
  authState$: Observable<any>;

  constructor() {
    const isDynamicConnect =
      this.window.location.hostname.startsWith('dynamic-connect');

    this.dsaAppShellService.updateConfig({
      theme: {
        brand: 'der-tour',
        mode: 'light'
      },
      contentLayout: 'dsa-content-fullscreen',
      header: {
        applicationTitle: {
          title: isDynamicConnect ? 'Dynamic Connect' : 'Core',
          logo: {
            iconName: isDynamicConnect ? 'rocket_launch' : 'elderly_woman'
          }
        },
        personalMenu: {
          visible: false
        }
      },
      navigation: {
        type: 'sideNavigation',
        sideNavigation: {
          enableAnimation: true
        }
      },
      footer: {
        text: `©Powered by Digital Solutions`,
        badge: {
          text: `${environment.name} - v${packageInfo.version}`
        },
        links: {
          slotFour: {
            // no impressum so text '' nothing is displayed...
            text: ''
          }
        }
      },
      backToTopButton: {
        visible: true,
        tooltipText: 'Back to top'
      }
    });

    this.titleService.setTitle(isDynamicConnect ? 'Dynamic Connect' : 'Core');
    this.dsaMenuService.menu = isDynamicConnect
      ? APPLICATION_MENU_DYNAMIC_CONNECT
      : APPLICATION_MENU_CORE;

    this.authState$ = this.store.pipe(select(fromApp.selectAuthState));
    this.authState$.subscribe(auth => {
      this.dsaAppShellService.updateConfig({
        header: {
          loginButton: {
            buttonText: auth.loggedIn ? 'Log out' : 'Log in'
          }
        }
      });
      if (
        isDynamicConnect &&
        (auth.roles as string[]).includes('DynamicConnect')
      ) {
        this.dsaMenuService.menu = APPLICATION_MENU_DYNAMIC_CONNECT_EDIT;
      }
    });

    this.oauthService.configure(environment.authConfig);
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    this.oauthService.loadDiscoveryDocumentAndTryLogin();
    this.oauthService.setupAutomaticSilentRefresh();
  }

  Login() {
    this.authState$
      .subscribe(auth =>
        this.store.dispatch(
          auth.loggedIn ? CoreActions.userLogout() : CoreActions.userLogin()
        )
      )
      .unsubscribe();
  }
}
