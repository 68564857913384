import { Injectable, inject } from '@angular/core';
import {
  RouteConfigLoadEnd,
  RouteConfigLoadStart,
  Router
} from '@angular/router';
import { CoreActions } from '@app/modules/core/actions/core.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { StorageMap } from '@ngx-pwa/local-storage';
import { setUser } from '@sentry/angular';
import { OAuthService } from 'angular-oauth2-oidc';
import { of } from 'rxjs';
import { exhaustMap, filter, mergeMap, tap } from 'rxjs/operators';
import { TokenService } from '../services/token.service';

@Injectable()
export class CoreEffects {
  private readonly actions$ = inject(Actions);
  private readonly router = inject(Router);
  private readonly storage = inject(StorageMap);
  private readonly oAuthService = inject(OAuthService);
  private readonly tokenService = inject(TokenService);

  loadingLazyModuleStarted$ = createEffect(() =>
    this.router.events.pipe(
      filter(e => e instanceof RouteConfigLoadStart),
      exhaustMap(_ => of(CoreActions.loadingLazyModuleStarted()))
    )
  );

  loadingLazyModuleFinished$ = createEffect(() =>
    this.router.events.pipe(
      filter(e => e instanceof RouteConfigLoadEnd),
      exhaustMap(_ => of(CoreActions.loadingLazyModuleFinished()))
    )
  );

  onLogin$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CoreActions.userLogin),
        mergeMap(() =>
          this.storage.set('redirectPathAfterLogin', window.location.pathname)
        ),
        tap(() => this.oAuthService.initCodeFlow())
      ),
    { dispatch: false }
  );

  onLogout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CoreActions.userLogout),
        tap(() => {
          this.oAuthService.logOut();
        })
      ),
    { dispatch: false }
  );

  onAuthServiceEvent$ = createEffect(() =>
    this.oAuthService.events.pipe(
      tap(_ => this.router.initialNavigation()),
      mergeMap(_ => of(CoreActions.userLoggedIn()))
    )
  );

  onUserLoggedIn$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CoreActions.userLoggedIn),
      filter(_ => this.oAuthService.hasValidAccessToken()),
      exhaustMap(_ =>
        of(
          CoreActions.updateAuthState({
            loggedIn: this.oAuthService.hasValidAccessToken(),
            token: this.oAuthService.getAccessToken(),
            roles: this.tokenService.getUserRoles(
              this.tokenService.getDecodedToken(
                this.oAuthService.getAccessToken()
              )
            ),
            tenants: this.tokenService.getTenants(
              this.tokenService.getDecodedToken(
                this.oAuthService.getAccessToken()
              )
            ),
            username: this.tokenService.getUsername(
              this.tokenService.getDecodedToken(
                this.oAuthService.getAccessToken()
              )
            ),
            givenname: this.tokenService.getGivenname(
              this.tokenService.getDecodedToken(
                this.oAuthService.getAccessToken()
              )
            ),
            familyname: this.tokenService.getFamilyname(
              this.tokenService.getDecodedToken(
                this.oAuthService.getAccessToken()
              )
            )
          })
        )
      )
    )
  );

  onAccessDenied$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CoreActions.accessDenied),
        tap(() => this.router.navigate(['access-denied']))
      ),
    { dispatch: false }
  );

  onUserAuthenticated$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CoreActions.updateAuthState),
        tap(userData => setUser({ username: userData.username })),
        mergeMap(_ => this.storage.get('redirectPathAfterLogin')),
        filter(result => !!result),
        mergeMap(result => this.router.navigate([result])),
        mergeMap(_ => this.storage.delete('redirectPathAfterLogin'))
      ),
    { dispatch: false }
  );
}
