import { Routes } from '@angular/router';
import { RoleGuard } from './modules/core/guards/role.guard';

export const routesForCore: Routes = [
  {
    path: '',
    canActivate: [RoleGuard],
    loadChildren: () =>
      import('./modules/booking/booking.module').then(mod => mod.BookingModule)
  },
  {
    path: 'access-denied',
    loadComponent: () =>
      import(
        './modules/core/components/access-denied/access-denied.component'
      ).then(c => c.AccessDeniedComponent)
  }
];

export const routesForDynamicConnect: Routes = [
  {
    path: '',
    canActivate: [RoleGuard],
    loadChildren: () =>
      import('./modules/dynamic-connect/dynamic-connect.module').then(
        mod => mod.DynamicConnectModule
      )
  },
  {
    path: 'access-denied',
    loadComponent: () =>
      import(
        './modules/core/components/access-denied/access-denied.component'
      ).then(c => c.AccessDeniedComponent)
  }
];
