import { CommonModule } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi
} from '@angular/common/http';
import {
  APP_INITIALIZER,
  ErrorHandler,
  enableProdMode,
  importProvidersFrom
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  RouteReuseStrategy,
  Router,
  provideRouter,
  withDisabledInitialNavigation
} from '@angular/router';
import {
  DataServiceError,
  DefaultDataServiceConfig,
  DefaultDataServiceFactory,
  EntityCollectionReducerMethodsFactory,
  EntityDataModule,
  HttpUrlGenerator,
  PersistenceResultHandler
} from '@ngrx/data';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StorageModule } from '@ngx-pwa/local-storage';
import { TraceService, createErrorHandler } from '@sentry/angular';
import * as Sentry from '@sentry/browser';
import { OAuthModule } from 'angular-oauth2-oidc';
import { FlexLayoutModule } from 'ng-flex-layout';
import { CookieModule } from 'ngx-cookie';
import { provideNgxMask } from 'ngx-mask';
import { AppComponent } from './app/app.component';
import { routesForCore, routesForDynamicConnect } from './app/app.routes';
import { CustomRouteReuseStrategy } from './app/custom-route-reuse-strategy';
import { AuthInterceptor } from './app/modules/core/authentication/auth.interceptor';
import { AdditionalEntityCollectionReducerMethodsFactory } from './app/modules/core/data-customization/additional-entity-collection-reducer-methods-factory';
import { AdditionalPropertyPersistenceResultHandler } from './app/modules/core/data-customization/additional-property-persistence-result-handler';
import { CoreDataServiceFactory } from './app/modules/core/data-customization/core-data-service-factory.service';
import { PluralHttpUrlGenerator } from './app/modules/core/data-customization/plural-http-url-generator';
import { Effects } from './app/modules/core/effects/index';
import { ApiEndpointsService } from './app/modules/core/services/api-endpoints.service';
import { REDUCERS, metaReducers } from './app/reducers';
import { environment } from './environments/environment';

function unwrapNgrxDataError(err: unknown): unknown {
  return err instanceof DataServiceError ? err.error : err;
}

if (environment.production) {
  enableProdMode();
}

Sentry.init({
  dsn: environment.sentryDsn,
  environment: environment.name,
  integrations: [
    // Registers and configures the Tracing integration,
    // which automatically instruments your application to monitor its
    // performance, including custom Angular routing instrumentation
    Sentry.browserTracingIntegration({})
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
});

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      FormsModule,
      FlexLayoutModule,
      ReactiveFormsModule,
      BrowserModule,
      CommonModule,
      StoreModule.forRoot(REDUCERS, {
        metaReducers,
        runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: true
        }
      }),
      StoreDevtoolsModule.instrument({
        maxAge: 25,
        logOnly: environment.production,
        connectInZone: true
      }),
      EffectsModule.forRoot(Effects),
      EntityDataModule.forRoot({}),
      StoreRouterConnectingModule.forRoot(),
      StorageModule.forRoot({ IDBNoWrap: true }),
      OAuthModule.forRoot(),
      CookieModule.withOptions()
    ),
    {
      provide: TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
        // This is intentional
      },
      deps: [TraceService],
      multi: true
    },
    {
      provide: DefaultDataServiceConfig,
      useValue: environment.defaultDataServiceConfig
    },
    {
      provide: ApiEndpointsService,
      useValue: new ApiEndpointsService(environment.apiEndpointsConfig)
    },
    {
      provide: ErrorHandler,
      useValue: createErrorHandler({
        extractor: (err, defaultExtractor) =>
          defaultExtractor(unwrapNgrxDataError(err)) || JSON.stringify(err)
      })
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HttpUrlGenerator, useClass: PluralHttpUrlGenerator },
    { provide: DefaultDataServiceFactory, useClass: CoreDataServiceFactory },
    {
      provide: PersistenceResultHandler,
      useClass: AdditionalPropertyPersistenceResultHandler
    },
    {
      provide: EntityCollectionReducerMethodsFactory,
      useClass: AdditionalEntityCollectionReducerMethodsFactory
    },
    { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy },
    { provide: Window, useValue: window },
    provideNgxMask(),
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
    provideRouter(
      window.location.hostname.startsWith('dynamic-connect')
        ? routesForDynamicConnect
        : routesForCore,
      withDisabledInitialNavigation()
    )
  ]
}).catch(err => console.error(err));
