export const environment = {
  production: false,
  name: 'Test',
  sentryDsn:
    'https://3e579cb049904ab9a23befb5aec6e0ef@o585713.ingest.sentry.io/5747195',
  instanaKey: '',
  authConfig: {
    loginUrl:
      'https://auth-t.core.travel/realms/IdentityCore/protocol/openid-connect/auth',
    issuer: 'https://auth-t.core.travel/realms/IdentityCore',
    redirectUri: window.location.origin,
    clientId: 'ceco',
    silentRefreshRedirectUri:
      window.location.origin + '/assets/oauth2-silent-refresh.html',
    responseType: 'code'
  },
  defaultDataServiceConfig: {
    root: '',
    timeout: 3000
  },
  apiEndpointsConfig: {
    bookings: 'https://booking-t.core.travel/api/bookings',
    bookingMappings: 'https://booking-t.core.travel/api/mappings',
    exception: 'https://exception-t.core.travel/api',
    requests: 'https://booking-t.core.travel/api/requests',
    masterdata: 'https://masterdata-t.core.travel/api',
    dynamicConnect: 'https://ui-dynamic-connect-t.core.travel/api'
  },
  powerBi: {
    reportUrl:
      'https://app.powerbi.com/reportEmbed?reportId=5451736a-ed8d-4524-8647-48b2903137a8&autoAuth=true&ctid=f10d619e-6154-45e4-9879-283640920dca&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLW5vcnRoLWV1cm9wZS1jLXByaW1hcnktcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D'
  },
  serviceDeskFeedbackEmail: ''
};

import 'zone.js/plugins/zone-error'; // Included with Angular CLI.
