import { Injectable, inject } from '@angular/core';
import { ToastActions } from '@app/modules/core/actions/toast.action';
import { EntityOp, ofEntityOp } from '@ngrx/data';
import { Actions, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class DataEffects {
  private readonly actions$ = inject(Actions);

  dataSaveSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofEntityOp(EntityOp.SAVE_ADD_MANY_SUCCESS, EntityOp.SAVE_ADD_ONE_SUCCESS),
      switchMap(_ =>
        of(
          ToastActions.successToast({
            title: 'Saved'
          })
        )
      )
    )
  );

  dataUpdateSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofEntityOp(
        EntityOp.SAVE_UPDATE_MANY_SUCCESS,
        EntityOp.SAVE_UPDATE_ONE_SUCCESS,
        EntityOp.SAVE_UPSERT_ONE_SUCCESS,
        EntityOp.SAVE_UPSERT_MANY_SUCCESS
      ),
      switchMap(_ =>
        of(
          ToastActions.successToast({
            title: 'Updated'
          })
        )
      )
    )
  );

  dataDeleteSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofEntityOp(
        EntityOp.SAVE_DELETE_MANY_SUCCESS,
        EntityOp.SAVE_DELETE_ONE_SUCCESS
      ),
      switchMap(_ =>
        of(
          ToastActions.successToast({
            title: 'Deleted'
          })
        )
      )
    )
  );

  dataError$ = createEffect(() =>
    this.actions$.pipe(
      ofEntityOp(
        EntityOp.QUERY_ALL_ERROR,
        EntityOp.QUERY_LOAD_ERROR,
        EntityOp.QUERY_MANY_ERROR,
        EntityOp.QUERY_BY_KEY_ERROR,
        EntityOp.SAVE_ADD_ONE_ERROR,
        EntityOp.SAVE_ADD_MANY_ERROR,
        EntityOp.SAVE_DELETE_ONE_ERROR,
        EntityOp.SAVE_UPDATE_ONE_ERROR,
        EntityOp.SAVE_UPSERT_ONE_ERROR,
        EntityOp.SAVE_DELETE_MANY_ERROR,
        EntityOp.SAVE_UPDATE_MANY_ERROR,
        EntityOp.SAVE_UPSERT_MANY_ERROR
      ),
      switchMap(_ => {
        let error = 'Error';

        if (!!_.payload.data && !!_.payload.data.error) {
          if (_.payload.data.error.message) {
            error = _.payload.data.error.message;
          } else if (
            !!_.payload.data.error.error &&
            !!_.payload.data.error.error.statusText
          ) {
            error = `${_.payload.data.error.error.statusText} (${_.payload.data.error.error.status})`;
          }
        }

        return of(
          ToastActions.errorToast({
            title: 'An Error Occurred',
            description: error
          })
        );
      })
    )
  );
}
