import { DsaApplicationMenuItems, DsaMenu } from '@dsa/design-system-angular';

const MENU_HOME: DsaMenu = {
  label: 'Home',
  selectable: true,
  path: ''
};

const MENU_BOOKING: DsaMenu = {
  label: 'Booking',
  selectable: false,
  items: [
    {
      label: 'Mapping',
      selectable: false,
      items: [
        {
          label: 'Hotel',
          path: 'mapping/hotel'
        },
        {
          label: 'Mealplan',
          path: 'mapping/meal-plan'
        },
        {
          label: 'Room Type',
          path: 'mapping/room-type'
        },
        {
          label: 'Client',
          path: 'mapping/client'
        },
        {
          label: 'Vehicle',
          path: 'mapping/vehicle'
        },
        {
          label: 'Sender Mail',
          path: 'mapping/sender-mail'
        }
      ]
    },
    {
      label: 'Store',
      selectable: false,
      items: [
        {
          label: 'Received Bookings',
          path: 'storage/received'
        }
      ]
    }
  ]
};

const MENU_EXCEPTIONS_BOOKING: DsaMenu = {
  label: 'Exception',
  selectable: false,
  items: [
    {
      label: 'Overview',
      selectable: false,
      items: [
        {
          label: 'Booking',
          path: 'exception/booking'
        },
        {
          label: 'History',
          path: 'exception/history'
        }
      ]
    }
  ]
};

const MENU_DASHBOARD: DsaMenu = {
  label: 'Dashboard',
  selectable: true,
  path: ''
};

const MENU_PORTFOLIO: DsaMenu = {
  label: 'Portfolio',
  path: '',
  selectable: false,
  items: [
    {
      label: 'Hotel Chains',
      path: 'hotel-chains'
    },
    {
      label: 'Hotels',
      path: 'hotels'
    }
  ]
};

const MENU_EXCEPTIONS_BUSINESS: DsaMenu = {
  label: 'Exceptions',
  path: 'exceptions',
  selectable: true,
  items: []
};

const MENU_BULK_EDIT: DsaMenu = {
  label: 'Bulk Edit',
  selectable: false,
  items: [
    {
      label: 'Activate Hotels',
      path: 'bulk-edit/activate-hotels'
    },
    {
      label: 'Deactivate Hotels',
      path: 'bulk-edit/deactivate-hotels'
    },
    {
      label: 'Delete Hotels',
      path: 'bulk-edit/delete-hotels'
    }
  ]
};

const MENU_MAPPING: DsaMenu = {
  label: 'Mapping',
  path: 'mapping',
  selectable: false,
  items: [
    {
      label: 'ExternalMapping',
      path: 'mapping/externalmapping'
    }
  ]
};

export const APPLICATION_MENU_CORE: DsaApplicationMenuItems = {
  items: [MENU_HOME, MENU_BOOKING, MENU_EXCEPTIONS_BOOKING]
};

export const APPLICATION_MENU_DYNAMIC_CONNECT: DsaApplicationMenuItems = {
  items: [
    MENU_DASHBOARD,
    MENU_EXCEPTIONS_BUSINESS,
    MENU_PORTFOLIO,
    MENU_MAPPING,
    MENU_BULK_EDIT
  ]
};
