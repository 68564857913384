{
  "name": "app",
  "jest": {
    "verbose": true,
    "testUrl": "http://test.url"
  },
  "prettier": {
    "trailingComma": "none",
    "endOfLine": "auto",
    "arrowParens": "avoid"
  },
  "version": "0.70.0-alpha.94",
  "scripts": {
    "ng": "ng",
    "start": "ng serve -c=local",
    "start:testsystem": "ng serve -c=localtest",
    "build": "ng build",
    "build:aot": "ng build --aot",
    "test": "ng test --code-coverage --watch=false",
    "test:nocov": "ng test --watch=false",
    "test:watch": "ng test --reporters mocha",
    "test:watchfocus": "ng test",
    "test:mutate": "stryker run",
    "lint": "eslint --c eslint.config.ts",
    "fixlint": "eslint --c eslint.config.ts --fix",
    "e2e": "ng e2e",
    "analyze": "ng build --stats-json && webpack-bundle-analyzer dist/app/stats-es2015.json",
    "sonar": "sonar-scanner",
    "sentry-cli": "sentry-cli"
  },
  "private": true,
  "dependencies": {
    "@angular-devkit/core": "^19.1.3",
    "@angular/animations": "^19.1.2",
    "@angular/cdk": "^19.1.0",
    "@angular/common": "^19.1.2",
    "@angular/compiler": "^19.1.2",
    "@angular/core": "^19.1.2",
    "@angular/forms": "^19.1.2",
    "@angular/material": "^19.1.0",
    "@angular/material-moment-adapter": "^19.1.0",
    "@angular/platform-browser": "^19.1.2",
    "@angular/platform-browser-dynamic": "^19.1.2",
    "@angular/router": "^19.1.2",
    "@dsa/design-system-angular": "^26.0.0",
    "@microsoft/signalr": "^8.0.7",
    "@ngrx/data": "^19.0.0",
    "@ngrx/effects": "^19.0.0",
    "@ngrx/entity": "^19.0.0",
    "@ngrx/operators": "^19.0.0",
    "@ngrx/router-store": "^19.0.0",
    "@ngrx/store": "^19.0.0",
    "@ngrx/store-devtools": "^19.0.0",
    "@ngx-pwa/local-storage": "^19.0.0",
    "@sentry/angular": "^8.50.0",
    "@sentry/browser": "^8.50.0",
    "@sentry/types": "^8.50.0",
    "@types/file-saver": "^2.0.7",
    "@types/lucene": "^2.1.7",
    "angular-eslint": "^19.0.2",
    "angular-oauth2-oidc": "^19.0.0",
    "angular-oauth2-oidc-jwks": "^17.0.2",
    "bytes": "^3.1.2",
    "dayjs": "^1.11.13",
    "file-saver": "^2.0.5",
    "filesize": "^10.1.6",
    "i": "^0.3.7",
    "jasmine-marbles": "^0.9.2",
    "jwt-decode": "^4.0.0",
    "lucene": "^2.1.1",
    "moment": "^2.30.1",
    "ng-flex-layout": "^19.0.0",
    "ngrx-signalr-core": "^18.0.0",
    "ngx-cookie": "^6.0.1",
    "ngx-json-viewer": "^3.2.1",
    "ngx-mask": "^19.0.6",
    "npm": "^11.0.0",
    "rxjs": "^7.8.1",
    "tslib": "^2.8.1",
    "typescript-eslint": "^8.21.0",
    "xml-formatter": "^3.6.3",
    "zone.js": "^0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^19.1.3",
    "@angular/cli": "^19.1.3",
    "@angular/compiler-cli": "^19.1.2",
    "@angular/language-service": "^19.1.2",
    "@dsa/eslint-plugin": "^7.2.0",
    "@ngrx/schematics": "^19.0.0",
    "@sentry/cli": "^2.41.1",
    "@stryker-mutator/core": "^8.7.1",
    "@stryker-mutator/karma-runner": "^8.7.1",
    "@stryker-mutator/typescript-checker": "^8.7.1",
    "@types/jasmine": "^5.1.5",
    "@types/jasminewd2": "^2.0.13",
    "@types/node": "^22.10.7",
    "eslint-config-prettier": "^10.0.1",
    "eslint-plugin-prettier": "^5.2.3",
    "jasmine-core": "^5.5.0",
    "jasmine-spec-reporter": "~7.0.0",
    "jiti": "^2.4.2",
    "karma": "^6.4.4",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-mocha-reporter": "^2.2.5",
    "karma-spec-reporter": "^0.0.36",
    "ng-mocks": "^14.13.2",
    "prettier": "^3.4.2",
    "sonarqube-scanner": "^4.2.6",
    "ts-node": "~10.9.2",
    "typescript": "^5.7.3",
    "webpack-bundle-analyzer": "^4.10.2"
  }
}
